import { RumEvent } from '@datadog/browser-rum';
import {
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';

// DataDog defines environments as prod, pre-prod, staging, etc.
const dataDogEnvironmentMap = new Map([
  ['production', 'prod'],
  ['staging', 'staging'],
]);

interface DatadogRUMOptions {
  applicationId: string;
  clientToken: string;
  service: string;
  env: string;
  version: string;
}

function importDatadogRum() {
  return import('@datadog/browser-rum');
}

let rumInstance: Awaited<ReturnType<typeof importDatadogRum>>['datadogRum'];
async function initializeDatadogRUM(
  rumOptions: DatadogRUMOptions
): Promise<void> {
  if (!isValidConfig(rumOptions) || rumInstance) {
    return;
  }

  const { datadogRum } = await importDatadogRum();
  datadogRum.init({
    applicationId: rumOptions.applicationId,
    clientToken: rumOptions.clientToken,
    site: 'datadoghq.com',
    service: rumOptions.service,
    env: dataDogEnvironmentMap.get(rumOptions.env),
    version: rumOptions.version,
    // Must be exact string matches, or regex patterns
    excludedActivityUrls: [
      // Sentry
      /^https:\/\/[^/]*\.ingest\.sentry\.io\/api/,

      // CDP or Evenstream tracking
      /^https:\/\/api\.squareup\.com\/2\.0\/log\/pixel_tracking/,
      /^https:\/\/api\.squareup\.com\/v1\/cdp\/batch/,

      // OneTrust non-blocking urls
      /^https:\/\/geolocation\.onetrust\.com/,
      /^https:\/\/privacyportal\.onetrust\.com/,
      /^https:\/\/cdn\.cookielaw\.org\/consent/,
      /^https:\/\/cdn\.cookielaw\.org\/scripttemplates/,
      /^https:\/\/cdn\.cookielaw\.org\/logos/,

      // Tracking
      /^https:\/\/www\.google-analytics\.com/,
      /^https:\/\/www\.googletagmanager\.com/,
      /^https:\/\/www\.googleadservices\.com/,

      // Martech tracking
      /^https:\/\/martech-production-c\.squarecdn\.com/,
      /^https:\/\/analytics\.google\.com/,
      /^https:\/\/capi\.squareup\.com/,
      /^https:\/\/[^/]*\.doubleclick\.net/,
      /^https:\/\/.*-sq-sgtm-prod\.cloudfunctions\.net\/sgtm_tag_monitoring/,
      /^https:\/\/www\.google\.com/,

      // Misc tracking found when viewing network tab
      /^https:\/\/connect\.facebook\.net/,
      /^https:\/\/[^/]*\.contentsquare.net/,
      /^https:\/\/www\.google\..*\/pagead/,
      /^https:\/\/pixel\.pointmediatracker\.com/,
      /^https:\/\/cdn\.blisspointmedia\.com/,
      /^https:\/\/adservice\.google\.com/,
      /^https:\/\/s\.pinimg\.com/,
      /^https:\/\/[^/]*\.pinterest\.com/,
    ],
    beforeSend: (event: RumEvent) => {
      if (
        event.type === 'error' &&
        event.error?.message?.includes('NEXT_REDIRECT')
      ) {
        return false;
      }

      return true;
    },
  });

  // eslint-disable-next-line require-atomic-updates
  rumInstance = datadogRum;
}

function isValidConfig(rumOptions: DatadogRUMOptions) {
  return (
    !isServerContext() &&
    isPublicContext() &&
    dataDogEnvironmentMap.has(rumOptions.env) &&
    rumOptions.applicationId &&
    rumOptions.clientToken
  );
}

export { initializeDatadogRUM };
