import {
  SpecificationGrammar,
  ExamplesGrammar,
  ContentVersionInfo,
  DEFAULT_SPEC_GRAMMAR,
  SpecificationVersionInfo,
  ExamplesVersionInfo,
} from '@squareup/dex-types-oas-path';

const SQUARE_NAMESPACE = 'square';
const DEFAULT_EXAMPLES_GRAMMAR = 'examples';
const ALPHA_VERSION = 'alpha';
const UNPUBLISHED_NAMESPACE = 'dev-build';
const NAMESPACE_ALLOWLIST = new Set([SQUARE_NAMESPACE, UNPUBLISHED_NAMESPACE]);

/**
 * Typeguards
 */

function isSpecGrammar(grammar: string): grammar is SpecificationGrammar {
  return grammar === 'open-api-3' || grammar === 'open-api-3-alpha';
}

function isExamplesGrammar(grammar: string): grammar is ExamplesGrammar {
  return grammar === 'examples' || grammar === 'examples-alpha';
}

function isTermalValue<TValue = unknown>(
  value: TValue | TValue[]
): value is TValue {
  return !Array.isArray(value);
}
/**
 * Extracts the first value from the given query field.
 */
function extractFirstValue<TValue = unknown>(
  value: TValue | TValue[] | undefined
): TValue | undefined {
  return value ? (isTermalValue(value) ? value : value[0]) : undefined;
}

/**
 * Takes in a spec path param for the API Reference or API Explorer
 * It is an `_` delimited string, where the first part is the namespace,
 * and the second part the version.
 *
 * If this is a dev-build, the namespace will be `dev-build`, and the
 * version will be an unpublished OAS spec document_id, as required
 * by the devs-content APIs - https://psychic-adventure-bb55bf0e.pages.github.io/#/
 * @param pathParam A path param for the API Reference
 */
function parsePathParam(
  pathParam: string | undefined,
  defaultGrammar: 'open-api-3' | 'examples'
): ContentVersionInfo | undefined {
  const namespaceVersion = pathParam?.split('_');

  if (!namespaceVersion) {
    return undefined;
  }

  const namespace =
    namespaceVersion.length > 0 ? namespaceVersion[0] : undefined;

  if (!namespace || !NAMESPACE_ALLOWLIST.has(namespace)) {
    return undefined;
  }
  // TODO : DEX-10218 refactor
  if (namespace === UNPUBLISHED_NAMESPACE) {
    if (namespaceVersion[1] === ALPHA_VERSION) {
      return {
        grammar: `${defaultGrammar}-alpha`,
        isUnpublished: true,
        namespace: `${namespace}_square`,
        version: namespaceVersion[2],
      };
    } else {
      return {
        grammar: defaultGrammar,
        isUnpublished: true,
        namespace: `${namespace}_square`,
        version: namespaceVersion[2],
      };
    }
  }

  // Namespace only provided.
  if (!namespaceVersion[1]) {
    return {
      grammar: defaultGrammar,
      isUnpublished: false,
      namespace,
    };
  }

  return {
    grammar: defaultGrammar,
    isUnpublished: false,
    namespace,
    version: namespaceVersion[1],
  };
}

function parseSpecPathParam(
  pathParam: string | undefined
): SpecificationVersionInfo | undefined {
  const versionInfo = parsePathParam(pathParam, DEFAULT_SPEC_GRAMMAR);

  if (!versionInfo) {
    return undefined;
  }

  if (!isSpecGrammar(versionInfo.grammar)) {
    throw new Error(
      `Specification grammar must be some form of "open-api-3". Got ${versionInfo.grammar}`
    );
  }

  return versionInfo as SpecificationVersionInfo;
}

/**
 * Returns the serialized path paramter in the expected format.
 * If no namespace is provided returns an empty string.
 * If no version is provided returns te namespace.
 */
function serializeSpecPathParam(
  namespace?: string | undefined,
  version?: string | undefined
) {
  if (!namespace) {
    return '';
  }

  if (!version) {
    return namespace;
  }

  return `${namespace}_${version}`;
}

function parseExamplesPathParam(
  pathParam: string | undefined
): ExamplesVersionInfo | undefined {
  const versionInfo = parsePathParam(pathParam, DEFAULT_EXAMPLES_GRAMMAR);

  if (!versionInfo) {
    return undefined;
  }

  if (!isExamplesGrammar(versionInfo.grammar)) {
    throw new Error(
      `Specification grammar must be some form of "examples". Got ${versionInfo.grammar}`
    );
  }

  return versionInfo as ExamplesVersionInfo;
}

export {
  parseSpecPathParam,
  parseExamplesPathParam,
  extractFirstValue,
  serializeSpecPathParam,
};
