import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SliceName,
  NullableStateNodeError,
  StoreStateNode,
  SliceError,
} from '@squareup/dex-types-data-state';
import { CodeExampleLanguage } from '@squareup/dex-types-oas';
import { DocPage, NavSet } from '@squareup/dex-types-shared-docs';

type ErrorViewState = {
  page: NullableStateNodeError;
  global: NullableStateNodeError;
};

type GlobalBannerViewState = {
  isDisplayed: boolean;
  variant: 'critical' | 'info' | 'insight' | 'success' | 'warning';
  dismissable: boolean;
  bannerText: string;
};

type CodeLanguageState = {
  lang: CodeExampleLanguage;
  isLoading: boolean;
};

type DevState = {
  guides: {
    page: StoreStateNode<{
      state: DocPage | null;
    }>;
    navSet: StoreStateNode<{
      state: NavSet | null;
    }>;
  };
};

type ShellViewState = {
  mobileNav: { isOpen: boolean };
  leftNav: { isCollapsed: boolean };
  errors: ErrorViewState;
  globalBanner: GlobalBannerViewState;
  search: {
    isOpen: boolean;
  };
  codeLanguage: CodeLanguageState;
  dev: DevState;
};

function isSliceError(data: unknown): data is SliceError {
  return Boolean((data as SliceError)?.status);
}

const initialState: ShellViewState = {
  mobileNav: {
    isOpen: false,
  },
  leftNav: {
    isCollapsed: false,
  },
  globalBanner: {
    isDisplayed: false,
    dismissable: false,
    variant: 'info',
    bannerText: '',
  },
  errors: {
    page: undefined,
    global: undefined,
  },
  search: {
    isOpen: false,
  },
  codeLanguage: {
    lang: 'curl',
    isLoading: false,
  },
  dev: {
    guides: {
      page: { isLoading: true },
      navSet: { isLoading: true },
    },
  },
};

const shellViewStateSlice = createSlice({
  initialState,
  name: SliceName.sharedShellViewState,
  reducers: {
    setMobileNavState(
      state: ShellViewState,
      { payload }: PayloadAction<{ isOpen: boolean }>
    ) {
      return {
        ...state,
        mobileNav: {
          ...state.mobileNav,
          isOpen: payload.isOpen,
        },
      };
    },
    toggleMobileNavIsOpen(state: ShellViewState) {
      return {
        ...state,
        mobileNav: {
          ...state.mobileNav,
          isOpen: !state.mobileNav.isOpen,
        },
      };
    },
    toggleLeftNavIsCollapsed(state: ShellViewState) {
      return {
        ...state,
        leftNav: {
          ...state.leftNav,
          isCollapsed: !state.leftNav.isCollapsed,
        },
      };
    },
    openSearch(state: ShellViewState) {
      return {
        ...state,
        search: {
          ...state.search,
          isOpen: true,
        },
      };
    },
    closeSearch(state: ShellViewState) {
      return {
        ...state,
        search: {
          ...state.search,
          isOpen: false,
        },
      };
    },
    setGlobalBanner(
      state: ShellViewState,
      { payload }: PayloadAction<GlobalBannerViewState>
    ) {
      return {
        ...state,
        globalBanner: payload,
      };
    },
    setGlobalError(
      state: ShellViewState,
      { payload }: PayloadAction<NullableStateNodeError>
    ) {
      return {
        ...state,
        errors: {
          ...state.errors,
          global: payload,
        },
      };
    },
    setPageError(
      state: ShellViewState,
      { payload }: PayloadAction<NullableStateNodeError>
    ) {
      return {
        ...state,
        errors: {
          ...state.errors,
          page: payload,
        },
      };
    },
    clearErrors(state: ShellViewState) {
      return {
        ...state,
        errors: {
          global: undefined,
          page: undefined,
        },
      };
    },
    setCodeLanguage(
      state: ShellViewState,
      { payload }: PayloadAction<CodeLanguageState>
    ) {
      return {
        ...state,
        codeLanguage: payload,
      };
    },
    setDevGuidePage(
      state: ShellViewState,
      { payload }: PayloadAction<{ state: DocPage | null } | SliceError>
    ) {
      const newGuidePage = isSliceError(payload)
        ? { error: payload }
        : { state: payload.state };

      return {
        ...state,
        dev: {
          ...state.dev,
          guides: {
            ...state.dev.guides,
            page: newGuidePage,
          },
        },
      };
    },
    setDevGuideNavset(
      state: ShellViewState,
      { payload }: PayloadAction<{ state: NavSet | null } | SliceError>
    ) {
      const newGuideNavSet = isSliceError(payload)
        ? { error: payload }
        : { state: payload.state };

      return {
        ...state,
        dev: {
          ...state.dev,
          guides: {
            ...state.dev.guides,
            navSet: newGuideNavSet,
          },
        },
      };
    },
  },
});

// Actions
const {
  setMobileNavState,
  toggleMobileNavIsOpen,
  toggleLeftNavIsCollapsed,
  setGlobalError,
  setPageError,
  openSearch,
  closeSearch,
  clearErrors,
  setGlobalBanner,
  setCodeLanguage,
  setDevGuideNavset,
  setDevGuidePage,
} = shellViewStateSlice.actions;

// Store config
const shellViewStateStoreConfig = {
  reducer: shellViewStateSlice.reducer,
  reducerPath: shellViewStateSlice.name,
};

export {
  setMobileNavState,
  toggleMobileNavIsOpen,
  toggleLeftNavIsCollapsed,
  setGlobalError,
  setPageError,
  shellViewStateStoreConfig,
  openSearch,
  closeSearch,
  clearErrors,
  setGlobalBanner,
  setCodeLanguage,
  setDevGuideNavset,
  setDevGuidePage,
  type ErrorViewState,
};
