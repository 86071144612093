import { ParsedUrlQuery } from 'querystring';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  extractFirstValue,
  parseSpecPathParam,
  serializeSpecPathParam,
} from '@squareup/dex-tech-utils-routing';
import { SliceName, StoreStateNode } from '@squareup/dex-types-data-state';
import { SpecificationVersionInfo } from '@squareup/dex-types-oas-path';

type ParsedInfo = { version?: string | undefined };

type SpecVersionInViewState = StoreStateNode<{
  /**
   * The version info of the set version in view.
   */
  versionInfo?: SpecificationVersionInfo;
  /**
   * The version info of the most recently parsed spec
   */
  parsedInfo?: ParsedInfo;
}>;

type ShellViewState = {
  specVersionInView: SpecVersionInViewState;
};

const initialState: ShellViewState = {
  specVersionInView: { isLoading: true },
};

const shellViewStateSlice = createSlice({
  initialState,
  name: SliceName.techRefShellViewState,
  reducers: {
    /**
     * Sets the current spec version in view.
     */
    setSpecVersionInView(
      state: ShellViewState,
      { payload }: PayloadAction<ParsedUrlQuery>
    ) {
      // 1 - Pull the version from the url
      const version = extractFirstValue(payload.square_version);

      const isVersionUnchanged =
        state.specVersionInView.versionInfo?.version === version ||
        // In cases the version in view is not the default the version and namespace are serialized into one token i.e. square_2022-08-23.
        serializeSpecPathParam(
          state.specVersionInView.versionInfo?.namespace,
          state.specVersionInView.versionInfo?.version
        ) === version;
      if (isVersionUnchanged) {
        return state;
      }

      // 2 - Validate version format and map to info
      const refSpecOptions = parseSpecPathParam(version);

      if (!refSpecOptions) {
        return {
          ...state,
          specVersionInView: {
            ...state.specVersionInView,
            error: {
              data: payload.square_version?.toString() || '',
              error: `Unable to parse spec version from path value:${payload.square_version}`,
              originalStatus: 404,
              status: 'PARSING_ERROR',
            },
            isLoading: false,
          },
        };
      } else {
        return {
          ...state,
          specVersionInView: {
            ...state.specVersionInView,
            versionInfo: refSpecOptions,
            error: undefined,
          },
        };
      }
    },
    /**
     * Sets the parsed state of the graph in view
     */
    setParsedGraphInView(
      state: ShellViewState,
      { payload }: PayloadAction<StoreStateNode<ParsedInfo>>
    ) {
      const { error, isLoading, version } = payload;
      return {
        ...state,
        specVersionInView: {
          ...state.specVersionInView,
          error,
          isLoading: isLoading || false,
          parsedInfo: {
            version,
          },
        },
      };
    },
  },
});

// Actions
const { setSpecVersionInView, setParsedGraphInView } =
  shellViewStateSlice.actions;

// Store config
const techRefShellViewStateStoreConfig = {
  reducer: shellViewStateSlice.reducer,
  reducerPath: shellViewStateSlice.name,
};

export {
  setSpecVersionInView,
  setParsedGraphInView,
  techRefShellViewStateStoreConfig,
};
